<template>
  <div class="wrapper ">
    <div class="flex align-start padding-lr-sm">
      <div class="flex-sub">
        <el-form :model="infoForm"
                 label-width="180px"
                 :rules="rules" ref="infoForm" class="add-form">
          <el-form-item label="姓名" prop="xingming">
            <el-input v-model="infoForm.xingming" placeholder="请填写姓名" disabled></el-input>
          </el-form-item>
          <el-form-item label="员工类型" prop="xingming">
            <el-input value="全职" placeholder="请填写员工类型" disabled></el-input>
          </el-form-item>
          <el-form-item label="岗位" prop="gangwei">
            <el-input v-model="infoForm.gangwei" placeholder="请填写岗位" disabled></el-input>
          </el-form-item>
          <el-form-item label="提交离职报告日期" prop="tijiaoriqi">
            <el-date-picker align="center"
                            class="w-all"
                            v-model="infoForm.tijiaoriqi"
                            type="date"
                            placeholder="请选择提交离职报告日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="离职日期" prop="lizhiriqi">
            <el-date-picker align="center"
                            class="w-all"
                            v-model="infoForm.lizhiriqi"
                            type="date"
                            placeholder="请选择离职日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="离职当月应工作天数" prop="yinggongzuotianshu">
            <el-input v-model="infoForm.yinggongzuotianshu" placeholder="请填写离职当月应工作天数" type="number">
              <template slot="append">天</template>
            </el-input>
          </el-form-item>
          <el-form-item label="离职当月实际工作天数" prop="shijigongzuotianshu">
            <el-input v-model="infoForm.shijigongzuotianshu" placeholder="请填写离职当月实际工作天数"  type="number">
              <template slot="append">天</template>
            </el-input>
          </el-form-item>
          <el-form-item label="离职原因" prop="lizhiyuanying">
            <el-select v-model="infoForm.lizhiyuanying" placeholder="请选择离职原因" class="w-all">
              <el-option
                  v-for="item in  reasonList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="其他离职原因" prop="lizhiyuanying_qita" v-if="infoForm.lizhiyuanying === 4">
            <el-input v-model="infoForm.lizhiyuanying_qita" placeholder="请填写其他离职原因"></el-input>
          </el-form-item>
          <el-form-item label-width="0" class="flex justify-around">
            <el-button class="submit-btn" type="primary" @click="submitForm('infoForm')">保存离职申请</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-divider direction="vertical" class="divider"></el-divider>
      <div class="flex-sub">
        <div v-if="isDepart" class="flex align-start justify-around flex-wrap">
          <el-card class="box-card w-all">
            <div slot="header" class="clearfix">
              <span class="font-size-lg text-blue text-bold"> <i class="el-icon-document"></i> 解除劳动合同</span>
              <el-button style="float: right; padding: 3px 0" type="text" @click="toEditTel(1)">编辑</el-button>
            </div>
            <div class="flex align-center justify-center">

              <el-link v-if="xieyi_state" :href="QAHOST + '/' +xieyi_state" target="_blank" type="primary">
                <el-button type="success" v-if="xieyi_state">查看签字离职协议</el-button>
              </el-link>
            </div>
          </el-card>
          <el-card class="box-card w-all margin-top">
            <div slot="header" class="clearfix">
              <span class="font-size-lg text-blue text-bold"> <i class="el-icon-document"></i> 离职交接表</span>
              <el-button style="float: right; padding: 3px 0" type="text" @click="toEditTel(2)">编辑</el-button>
            </div>
            <div class="flex align-center justify-center">
              <el-link v-if="jiaojiebiao_state" :href="QAHOST + '/' +jiaojiebiao_state" target="_blank" type="primary">
                <el-button type="success">查看签字离职交接表</el-button>
              </el-link>
            </div>
          </el-card>
          <el-card class="box-card w-all margin-top">
            <div slot="header" class="clearfix">
              <span class="font-size-lg text-blue text-bold"> <i class="el-icon-document"></i> 薪酬核算单</span>
              <el-button style="float: right; padding: 3px 0" type="text" @click="toEditTel(3)">编辑</el-button>
            </div>
            <div class="flex align-center justify-center">


              <el-link v-if="xinchou_state" :href="QAHOST + '/' + xinchou_state" target="_blank" type="primary">
                <el-button type="success">查看签字薪酬核算单</el-button>
              </el-link>
            </div>
          </el-card>
          <el-card class="box-card w-all margin-top">
            <div slot="header" class="clearfix">
              <span class="font-size-lg text-blue text-bold"> <i class="el-icon-document"></i> 离职证明</span>
              <el-button style="float: right; padding: 3px 0" type="text" @click="toEditTel(4)">编辑</el-button>
            </div>
            <div class="flex align-center justify-center">

              <el-link v-if="zhengming_state" :href="QAHOST + '/' +zhengming_state" target="_blank" type="primary">
                <el-button type="success">查看盖章离职证明</el-button>
              </el-link>

            </div>
          </el-card>
        </div>
        <div v-if="isDepart && state === 2" class="flex align-center justify-around margin-top">
          <el-button class="submit-btn" type="danger" @click="reductionItem">取消离职申请
          </el-button>

          <el-button
              v-if="xieyi_state && jiaojiebiao_state && xinchou_state && zhengming_state"
              class="submit-btn" type="primary" @click="endItem">办理完结</el-button>
        </div>
        <div v-if=" state === 3" class="flex align-center justify-around margin-top">
          <el-button class="submit-btn" type="info" >离职已完结</el-button>
        </div>
      </div>
    </div>



    <el-dialog
        title="请选择下级员工领导指派："
        :visible.sync="dialogVisible"
        width="30%"
        :show-close="false"
        :close-on-click-modal="false">

      <div class="flex align-center justify-between">
        <el-select v-model="zhijieshangji" placeholder="请选择直接上级" class="w-all">
          <el-option
              v-for="item in superior"
              :key="item.id"
              :label="item.xingming"
              :value="item.id">
          </el-option>
        </el-select>
      </div>


      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureEnd">确 定</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>

import rules from "@/utils/rules";
import {reasonList} from "@/utils/const";
import {
  checkMyChildStaff,
  deleteDepart,
  departSetOver,
  departStaffFullTime,
  departStaffFullTimeShow
} from "@/api/resignation";
import {getBaseDataByOfferId} from "@/api/common";
import {zhDate} from "@/utils";
import {shangJiList} from "@/api/entry";
import {QAHOST} from "@/config";

export default {
  name: "OrgList",
  data() {
    return {
      id: 0,
      state:0,
      QAHOST: QAHOST,
      isDepart: false,
      reasonList: reasonList,

      isEnd: false,
      infoForm: {
        xingming: '',
        gangwei: '',
        tijiaoriqi: '',
        lizhiriqi: '',
        yinggongzuotianshu: '',
        shijigongzuotianshu: '',
        lizhiyuanying: '',
        lizhiyuanying_qita: '',
      },
      rules: {
        xingming: rules.mustInput,
        gangwei: rules.mustInput,
        tijiaoriqi: rules.mustDate,
        lizhiriqi: rules.mustDate,
        yinggongzuotianshu: rules.mustInput,
        shijigongzuotianshu: rules.mustInput,
        lizhiyuanying: rules.mustSelect,
      },

      xieyi_state: '',
      jiaojiebiao_state: '',
      xinchou_state: '',
      zhengming_state: '',

      dialogVisible:false,
      superior: [],
      zhijieshangji:0,

    }
  },
  created() {
    this.$emit("setIndex", '/admin/resignationList', "全职员工离职信息", true);
    this.id = Number(this.$route.params.id)
    this.shangJiList()
    this.getDepartInfo()
  },
  components: {},
  methods: {

    //  取消
    reductionItem() {
      this.$confirm('您确定要取消离职申请吗？', "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "msg-btn",
        confirmButtonClass: "msg-btn",
        center: true
      }).then(() => {
        this.sureDelete();
      });
    },
    async sureDelete() {
      let _state = await deleteDepart(this.id);
      this.$message.success(_state.info);
      this.$router.go(-1)
    },

    endItem() {
      this.$confirm('您确定要完结离职申请吗？', "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "msg-btn",
        confirmButtonClass: "msg-btn",
        center: true
      }).then(() => {
        this.checkMyChildStaff()
      });
    },

    async checkMyChildStaff() {
      let _state = await checkMyChildStaff(this.id);
       if(_state.data === 0){
         this.zhijieshangji = 0
         await this.sureEnd()
       }else{
         this.dialogVisible = true
       }
    },


    async sureEnd() {
      let _state = await departSetOver(this.id,this.zhijieshangji);
      this.$message.success(_state.info);
      this.dialogVisible = false
      await this.getDepartInfo()
    },



    //  获取上级
    async shangJiList() {
      const res = await shangJiList()
      this.superior = res.data
    },


    //  获取离职信息
    async getDepartInfo() {
      const res = await departStaffFullTimeShow(this.id)
      if (res.data) {
        this.infoForm = {
          xingming: res.data.xingming,
          gangwei: res.data.gangwei,
          tijiaoriqi: new Date(res.data.tijiaoriqi),
          lizhiriqi: new Date(res.data.lizhiriqi),
          yinggongzuotianshu: res.data.yinggongzuotianshu,
          shijigongzuotianshu: res.data.shijigongzuotianshu,
          lizhiyuanying: res.data.lizhiyuanying,
          lizhiyuanying_qita: res.data.lizhiyuanying_qita,
        }
        this.xieyi_state = res.data.xieyi_state
        this.jiaojiebiao_state = res.data.jiaojiebiao_state
        this.xinchou_state = res.data.xinchou_state
        this.zhengming_state = res.data.zhengming_state
        this.isDepart = true
        this.state = res.data.zaizhilizhi
      } else {
        const info = await getBaseDataByOfferId(this.id)
        this.infoForm.xingming = info.data.staff.xingming
        this.infoForm.gangwei = info.data.staff.gangwei
      }
    },
    //  提交信息
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureAdd()
        }
      });
    },
    async sureAdd() {
      let _state = await departStaffFullTime(
        this.id,
        this.infoForm.xingming,
        this.infoForm.gangwei,
        zhDate(new Date(this.infoForm.tijiaoriqi)),
        zhDate(new Date(this.infoForm.lizhiriqi)),
        this.infoForm.yinggongzuotianshu,
        this.infoForm.shijigongzuotianshu,
        this.infoForm.lizhiyuanying,
        this.infoForm.lizhiyuanying_qita,
      )
      if (_state.status === 200) {
        this.$message.success(_state.info)
        await this.getDepartInfo()
      } else {
        this.$message.error(_state.info)
      }
    },
    //  取消离职信息
    //  离职完结

    //  编辑模版
    toEditTel(num) {
      if (num === 1) this.$router.push(`/admin/fullTime/protocol/${this.id}`)
      if (num === 2) this.$router.push(`/admin/fullTime/handover/${this.id}`)
      if (num === 3) this.$router.push(`/admin/fullTime/salary/${this.id}`)
      if (num === 4) this.$router.push(`/admin/fullTime/prove/${this.id}`)
    }
  },
  filters: {},
  watch: {},
}
</script>

<style scoped lang="scss">

</style>
